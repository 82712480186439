import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Loading',
})
export default class Loading extends Vue {
  @Prop({ default: false })
  isLoading!: boolean;

  @Prop({ default: '#1c4454' })
  color?: string;

  @Prop({ default: 50 })
  size?: number;

  @Prop({ default: 6 })
  width?: number;
}
