export const findIndex = (array: Array<any>, id: number) => {
  return array.findIndex(item => item.id === id);
};

export const findAndRemove = (array: Array<any>, id: number) => {
  const index = findIndex(array, id);
  array.splice(index, 1);
};

export const findAndReplace = (array: Array<any>, id: number, payload: any) => {
  const index = findIndex(array, id);
  array.splice(index, 1, payload);
};
