import VueRouter from 'vue-router';
import store from '@/store';

export const AuthenticateRoute = (router: VueRouter) => {
  router.beforeEach((to, from, next) => {
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = store.getters.isAuthenticated;

    if (authRequired && !loggedIn) {
      next('/login');
    } else {
      next();
    }
  });

  //Redirect already loged in users from guest pages
  router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.guest)) {
      if (store.getters.isAuthenticated) {
        next('/');
        return;
      }
    }
    next();
  });
};
