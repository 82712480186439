import { Component, Vue, Prop } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { Action, Getter, Mutation } from 'vuex-class';
import { Employee } from '@/models';
import { CurrencyType, MessageType } from '@/enums';
import ConfirmationModal from '@components/confirmationModal/index.vue';
import ReviewModal from '../modal/index.vue';

@Component({
  name: 'ReviewTable',
  components: { ReviewModal, ConfirmationModal },
})
export default class ReviewTable extends Vue {
  dialogIsActive = false;
  confirmDialogIsActive = false;
  selectedItemId = 0;

  headers: DataTableHeader[] = [
    { text: 'Review date', value: 'reviewDate' },
    { text: 'Title', value: 'title' },
    { text: 'Notes', value: 'notes' },
    { text: 'Salary after review', value: 'afterSalary' },
    { text: 'Salary before review', value: 'beforeSalary' },
  ];

  @Prop()
  employeeId!: number;

  @Getter('getEmployeeReviews')
  employeeReviews!: any;

  @Getter('getCurrency')
  currencySign!: any;

  @Getter('getCurrencyRate')
  currencyRate!: any;

  @Action('GetCurrentReviews')
  getCurrentEmployee!: (id: number) => Promise<Employee>;

  @Action('DeleteReview')
  deleteReview!: ({ employeeId, id }: any) => Promise<void>;

  @Action('ShowMessage')
  showMessage!: (payload: any) => void;

  @Mutation('setCurrency')
  setCurrency!: (payload: any) => void;

  @Mutation('setCurrencyRate')
  setCurrencyRate!: (payload: any) => void;

  showDialog(id: number) {
    this.dialogIsActive = true;
    this.selectedItemId = id;
  }

  showConfirmationDialog(e: any, id: number) {
    e.stopPropagation();
    this.confirmDialogIsActive = true;
    this.selectedItemId = id;
  }

  async confirm(isConfirmed: boolean) {
    if (isConfirmed) {
      await this.deleteReview({
        employeeId: this.employeeId,
        id: this.selectedItemId,
      });
    }
    this.confirmDialogIsActive = false;
  }

  calculatedReviews(key: any) {
    const total = this.employeeReviews.reduce(
      (a: any, b: any) => a + (b[key] * this.currencyRate || 0),
      0
    );
    return this.parseSalary(total);
  }

  parseSalary(num: any) {
    return Number.parseFloat(num).toFixed(2);
  }

  async created() {
    try {
      await this.getCurrentEmployee(this.employeeId);
      this.setCurrency(
        this.currencySign ? this.currencySign : CurrencyType.Dram
      );
      this.setCurrencyRate(this.currencyRate);
    } catch (error) {
      this.showMessage({
        content: error,
        type: MessageType.Error,
      });
    }
  }
}
