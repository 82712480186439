const state = { content: '', type: '', show: false };

const getters = {
  getToastText: (state: any) => state.content,
  getToastType: (state: any) => state.type,
  getToastActive: (state: any) => state.show,
};

const actions = {
  ShowMessage({ commit }: any, payload: any) {
    commit('showMessage', payload);
  },
  SetActive({ commit }: any, payload: any) {
    commit('setActive', payload);
  },
};

const mutations = {
  showMessage(state: any, payload: any) {
    state.content = payload.content;
    state.type = payload.type;
    state.show = true;
  },
  setActive(state: any, payload: any) {
    state.show = payload;
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
