import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Img',
})
export default class Img extends Vue {
  @Prop({ default: '' })
  alt!: string;

  @Prop({ default: '' })
  src!: string | object;

  @Prop({ default: '' })
  width!: number | string;

  @Prop({ default: '' })
  minWidth!: number | string;

  @Prop({ default: '' })
  maxWidth!: number | string;

  @Prop({ default: '' })
  height!: number | string;

  @Prop({ default: '' })
  minHeight!: number | string;

  @Prop({ default: '' })
  maxHeight!: number | string;

  @Prop({ default: '' })
  aspectRatio!: string | number;

  @Prop({ default: false })
  contain!: boolean;

  @Prop({ default: 'center' })
  position!: string;

  @Prop({ default: false })
  dark!: boolean;

  @Prop({ default: false })
  light!: boolean;

  @Prop({ default: false })
  eager!: boolean;

  @Prop({ default: '' })
  gradient!: string;

  @Prop({ default: '' })
  lazySrc!: string;

  @Prop({ default: '' })
  srcset!: string;
}
