import { Component, Prop, Vue } from 'vue-property-decorator';
import { Size } from '@components/types';

@Component({
  name: 'SizeMixin',
})
export class SizeMixin extends Vue {
  @Prop({ default: 'medium' })
  size!: Size;

  public get isExtraSmall(): boolean {
    return this.size === 'x-small';
  }

  public get isSmall(): boolean {
    return this.size === 'small';
  }

  public get isMedium(): boolean {
    return this.size === 'medium';
  }

  public get isLarge(): boolean {
    return this.size === 'large';
  }

  public get isExtraLarge(): boolean {
    return this.size === 'x-large';
  }
}
