import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { EmployeeReview, ReviewDefault } from '@/models';
import { MessageType } from '@/enums';
import { helper } from '@/helper';
import DateField from '@shared/DateField/index.vue';
import api from '@utils/api';

@Component({
  name: 'ReviewModal',
  components: {
    DateField,
  },
})
export default class ReviewModal extends Vue {
  requiredFields: any[] = [];
  titleField: any[] = [];
  notesField: any[] = [];
  isValid = false;

  @Prop()
  employeeId!: number;

  @Prop({ default: 0 })
  reviewId!: number;

  @Prop({ default: false })
  dialogIsActive!: boolean;

  @Prop({ default: 'Add Review' })
  dialogTitle!: string;

  @Prop({ default: false })
  isEdit!: boolean;

  @Action('CreateReview')
  createReview!: (reviewModel: EmployeeReview) => Promise<void>;

  @Action('EditReview')
  editReview!: (reviewModel: EmployeeReview) => Promise<void>;

  review = ReviewDefault;

  async created() {
    if (this.reviewId > 0)
      try {
        this.review = await api.get({
          url: `employee/${this.employeeId}/review/${this.reviewId}`,
        });
      } catch (error) {
        // Handle error
      }
  }

  @Action('ShowMessage')
  showMessage!: (payload: any) => void;

  getValidationRules() {
    this.requiredFields = [helper.required];
    this.titleField = [helper.required, helper.maxChars(20)];
    this.notesField = [helper.required, helper.maxChars(512)];
  }

  async createOrEdit() {
    const body = {
      id: Number(this.reviewId),
      employeeId: Number(this.employeeId),
      reviewDate: this.review.reviewDate,
      title: this.review.title,
      notes: this.review.notes,
      afterSalary: Number(this.review.afterSalary),
      beforeSalary: Number(this.review.beforeSalary),
    };

    try {
      if (!this.isValid) {
        this.showMessage({
          content: 'Missing Or Invalid Fields',
          type: MessageType.Error,
        });
        return;
      }
      if (this.isEdit) {
        await this.editReview(body);
        this.showMessage({
          content: 'Review Edited',
        });
      } else {
        await this.createReview(body);
        this.showMessage({
          content: 'Review Added',
        });
      }
      this.$emit('close');
    } catch (error) {
      this.showMessage({
        content: error,
        type: MessageType.Error,
      });
    }
  }

  checkValidation(action: any) {
    this.getValidationRules();
    setTimeout(() => {
      if (
        (this.$refs.reviewForm as Vue & {
          validate: () => boolean;
        }).validate()
      ) {
        this.isValid = true;
      }
      action();
    });
  }
}
