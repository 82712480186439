import api from '@utils/api';
import { LogIn, LogInResponse } from '@pages/logIn/models';

const state = {
  user: null,
  token: null,
};

const getters = {
  isAuthenticated: (state: any) => !!state.token,
  getUser: (state: any) => state.user,
  getToken: (state: any) => state.token,
};

const actions = {
  async LogIn({ commit }: any, loginModel: LogIn) {
    const response: LogInResponse = await api.post({
      url: 'auth/login',
      body: loginModel,
    });
    const { accessToken: token } = response;
    if (token) {
      await commit('setToken', token);
    } else {
      await commit('setToken', null);
      throw 'Incorrect Username or Password';
    }
  },
  async LogOut({ commit }: any) {
    await commit('logOut');
  },
  async GetCurrentUser({ commit }: any) {
    const response = await api.get({ url: 'user/me' });
    if (response.message) {
      await commit('setUser', null);
    } else {
      await commit('setUser', response);
    }
  },
};

const mutations = {
  setUser(state: any, user: any) {
    state.user = user;
  },
  setToken(state: any, token: any) {
    state.token = token;
  },
  logOut(state: any) {
    state.user = null;
    state.token = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
