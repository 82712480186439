export default [
  {
    method: 'get',
    url: /.*\/user\/me.*/,
    statusCode: 200,
    body: {
      id: 1,
      firstName: 'Hovhannes',
      lastName: 'Shitikyan',
      username: 'Hovo',
      isAdmin: true,
      active: true,
    },
  },
];
