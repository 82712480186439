import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Row',
})
export default class Row extends Vue {
  @Prop({ default: null })
  align!: string;

  @Prop({ default: null })
  alignContent!: string;

  @Prop({ default: null })
  alignContentLg!: string;

  @Prop({ default: null })
  alignContentMd!: string;

  @Prop({ default: null })
  alignContentSm!: string;

  @Prop({ default: null })
  alignContentXl!: string;

  @Prop({ default: null })
  alignLg!: string;

  @Prop({ default: null })
  alignMd!: string;

  @Prop({ default: null })
  alignSm!: string;

  @Prop({ default: null })
  alignXl!: string;

  @Prop({ default: false })
  dense!: boolean;

  @Prop({ default: null })
  justify!: string;

  @Prop({ default: null })
  justifyLg!: string;

  @Prop({ default: null })
  justifyMd!: string;

  @Prop({ default: null })
  justifySm!: string;

  @Prop({ default: null })
  justifyXl!: string;

  @Prop({ default: false })
  noGutters!: boolean;

  @Prop({ default: 'div' })
  tag!: string;
}
