import { Vue, Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { LogIn as LogInModel } from './models';
import { MessageType } from '@/enums';
import { helper } from '@/helper';

@Component({
  name: 'LogIn',
})
export default class LogIn extends Vue {
  email = '';
  password = '';
  passwordMinLength = 8;
  showPassword = false;
  usernameField: any[] = [];
  passwordField: any[] = [];
  isValid = false;

  @Action('LogIn')
  logIn!: (model: LogInModel) => Promise<void>;

  @Action('GetCurrentUser')
  getCurrentUser!: () => Promise<void>;

  @Action('ShowMessage')
  showMessage!: (payload: any) => void;

  handleLogin() {
    this.usernameField = [helper.required, helper.maxChars(20)];
    this.passwordField = [helper.required, helper.minChars(8)];
    const body: LogInModel = {
      username: this.email,
      password: this.password,
    };
    setTimeout(async () => {
      if (
        (this.$refs.loginForm as Vue & {
          validate: () => boolean;
        }).validate()
      ) {
        this.isValid = true;
      }
      try {
        if (!this.isValid) {
          this.showMessage({
            content: 'Missing Or Invalid Fields',
            type: MessageType.Error,
          });
          return;
        }
        await this.logIn(body);
        await this.getCurrentUser();
        this.$router.push('/');
      } catch (err) {
        this.showMessage({
          content: err,
          type: MessageType.Error,
        });
      }
    });
  }
}
