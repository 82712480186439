import { Component, Prop, Vue } from 'vue-property-decorator';
import { CommonMixin } from '@/mixins';

@Component({
  name: 'Menu',
  mixins: [CommonMixin],
})
export default class Menu extends Vue {
  @Prop({ default: false })
  absolute!: boolean;

  @Prop({ default: '' })
  activator!: any;

  @Prop({ default: false })
  allowOverflow!: boolean;

  @Prop({ default: false })
  attach!: any;

  @Prop({ default: false })
  auto!: boolean;

  @Prop({ default: false })
  bottom!: boolean;

  @Prop({ default: 0 })
  closeDelay!: number | string;

  @Prop({ default: true })
  closeOnClick!: boolean;

  @Prop({ default: true })
  closeOnContentClick!: boolean;

  @Prop({ default: '' })
  contentClass!: string;

  @Prop({ default: false })
  dark!: boolean;

  @Prop({ default: false })
  disableKeys!: boolean;

  @Prop({ default: false })
  eager!: boolean;

  @Prop({ default: false })
  fixed!: boolean;

  @Prop({ default: false })
  internalActivator!: boolean;

  @Prop({ default: false })
  left!: boolean;

  @Prop({ default: false })
  light!: boolean;

  @Prop({ default: '' })
  minWidth!: number | string;

  @Prop({ default: 'auto' })
  maxWidth!: number | string;

  @Prop({ default: 'auto' })
  maxHeight!: number | string;

  @Prop({ default: 0 })
  nudgeBottom!: number | string;

  @Prop({ default: 0 })
  nudgeLeft!: number | string;

  @Prop({ default: 0 })
  nudgeRight!: number | string;

  @Prop({ default: 0 })
  nudgeTop!: number | string;

  @Prop({ default: 0 })
  nudgeWidth!: number | string;

  @Prop({ default: false })
  offsetOverflow!: boolean;

  @Prop({ default: false })
  offsetX!: boolean;

  @Prop({ default: false })
  offsetY!: boolean;

  @Prop({ default: 0 })
  openDelay!: number | string;

  @Prop({ default: true })
  openOnClick!: boolean;

  @Prop({ default: false })
  openOnFocus!: boolean;

  @Prop({ default: false })
  openOnHover!: boolean;

  @Prop({ default: 'top left' })
  origin!: string;

  @Prop({ default: undefined })
  positionX!: number;

  @Prop({ default: undefined })
  positionY!: number;

  @Prop({ default: '' })
  returnValue!: any;

  @Prop({ default: false })
  right!: boolean;

  @Prop({ default: '' })
  rounded!: boolean | string;

  @Prop({ default: false })
  tile!: boolean;

  @Prop({ default: false })
  top!: boolean;

  @Prop({ default: 'scale-transition' })
  transition!: string | boolean;

  @Prop({ default: '' })
  value!: any;

  @Prop({ default: '' })
  zIndex!: number | string;
}
