import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Dropdown',
})
export default class Dropdown extends Vue {
  @Prop()
  items!: any;

  @Prop({ default: false })
  solo!: boolean;

  @Prop({ default: '' })
  itemText!: string;

  @Prop({ default: '' })
  label!: string;

  handleOption(e: any) {
    this.$emit('option', e);
  }
}
