export enum MessageType {
  Error = 'ERROR',
  Success = 'SUCCESS',
}

export enum CurrencyType {
  Dollar = '$',
  Dram = '֏',
  Ruble = '₽',
  Pound = '£',
  Euro = '€',
}

export enum CurrencyExchangeType {
  Dollar = 'USD',
  Dram = 'AMD',
  Ruble = 'RUB',
  Pound = 'GBP',
  Euro = 'EUR',
}
