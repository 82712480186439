import { Component, Prop, Vue } from 'vue-property-decorator';
import { CommonMixin } from '@/mixins';

@Component({
  name: 'DatePicker',
  mixins: [CommonMixin],
})
export default class DatePicker extends Vue {
  @Prop({ default: null })
  allowedDates!: Function;

  @Prop({ default: '' })
  color!: string;

  @Prop({ default: false })
  dark!: boolean;

  @Prop({ default: null })
  dayFormat!: Function;

  @Prop({ default: '' })
  elevation!: number | string;

  @Prop({ default: '' })
  eventColor!: string;

  @Prop({ default: null })
  events!: Function;

  @Prop({ default: 0 })
  firstDayOfWeek!: number | string;

  @Prop({ default: false })
  flat!: boolean;

  @Prop({ default: false })
  fullWidth!: boolean;

  @Prop({ default: '' })
  headerColor!: string;

  @Prop({ default: null })
  headerDateFormat!: Function;

  @Prop({ default: false })
  landscape!: boolean;

  @Prop({ default: false })
  light!: boolean;

  @Prop({ default: '' })
  locale!: string;

  @Prop({ default: 0 })
  localeFirstDayOfYear!: number | string;

  @Prop({ default: '' })
  max!: string;

  @Prop({ default: '' })
  min!: string;

  @Prop({ default: null })
  monthFormat!: Function;

  @Prop({ default: false })
  multiple!: boolean;

  @Prop({ default: '$next' })
  nextIcon!: string;

  @Prop({ default: '$vuetify.datePicker.nextMonthAriaLabel' })
  nextMonthAriaLabel!: string;

  @Prop({ default: '$vuetify.datePicker.nextYearAriaLabel' })
  nextYearAriaLabel!: string;

  @Prop({ default: false })
  noTitle!: boolean;

  @Prop({ default: '' })
  pickerDate!: string;

  @Prop({ default: '$prev' })
  prevIcon!: string;

  @Prop({ default: '$vuetify.datePicker.prevMonthAriaLabel' })
  prevMonthAriaLabel!: string;

  @Prop({ default: '$vuetify.datePicker.prevYearAriaLabel' })
  prevYearAriaLabel!: string;

  @Prop({ default: false })
  range!: boolean;

  @Prop({ default: false })
  reactive!: boolean;

  @Prop({ default: false })
  readonly!: boolean;

  @Prop({ default: false })
  scrollable!: boolean;

  @Prop({ default: '$vuetify.datePicker.itemsSelected' })
  selectedItemsText!: string;

  @Prop({ default: true })
  showCurrent!: boolean | string;

  @Prop({ default: false })
  showWeek!: boolean;

  @Prop({ default: null })
  titleDateFormat!: Function;

  @Prop({ default: 'date' })
  type!: string;

  @Prop({ default: '' })
  value!: string;

  @Prop({ default: null })
  weekdayFormat!: Function;

  @Prop({ default: 290 })
  width!: number | string;

  @Prop({ default: null })
  yearFormat!: Function;

  @Prop({ default: '' })
  yearIcon!: string;
}
