export default [
  {
    method: 'get',
    url: /.*\/employee\/[0-9]*.*/,
    statusCode: 200,
    body: {
      id: 1,
      firstName: 'Hayk',
      lastName: 'Karyan',
      title: 'Junior Web Developer',
      nationality: 'Armenian',
      country: 'Armenia',
      city: 'Noyemberyan',
      email: 'karyan.hk.3101@gmail.com',
      address: 'Berdavan',
      phoneNumber: 37494790043,
      salary: 10,
      officialSalary: 24,
      joinedOn: '2020-06-01',
      updatedSalaryOnDate: '2020-10-01',
      nextReviewDate: '2021-01-01',
      onProbation: false,
      active: true,
    },
  },
  {
    method: 'get',
    url: /.*\/employee.*/,
    statusCode: 200,
    body: [
      {
        id: 1,
        firstName: 'Hayk',
        lastName: 'Karyan',
        title: 'Junior Web Developer',
        nationality: 'Armenian',
        country: 'Armenia',
        city: 'Noyemberyan',
        email: 'karyan.hk.3101@gmail.com',
        address: 'Berdavan',
        phoneNumber: 37494790043,
        salary: 10,
        officialSalary: 24,
        joinedOn: '2020-06-01',
        updatedSalaryOnDate: '2020-10-01',
        nextReviewDate: '2021-01-01',
        onProbation: false,
        active: true,
      },
      {
        id: 2,
        firstName: 'Arayik',
        lastName: 'Hovhannisyan',
        title: 'Junior Web Developer',
        nationality: 'Armenian',
        country: 'Armenia',
        city: 'Yerevan',
        email: 'ara.hovhannisyan99@gmail.com',
        address: 'Yerevan',
        phoneNumber: 37499999999,
        salary: 10,
        officialSalary: 24,
        joinedOn: '2020-09-01',
        updatedSalaryOnDate: '2020-10-01',
        nextReviewDate: '2020-12-01',
        onProbation: true,
        active: false,
      },
    ],
  },
  {
    method: 'post',
    url: /.*\/employee.*/,
    statusCode: 200,
    body: [
      {
        id: 1,
        firstName: 'Hayk',
        lastName: 'Karyan',
        title: 'Junior Web Developer',
        nationality: 'Armenian',
        country: 'Armenia',
        city: 'Noyemberyan',
        email: 'karyan.hk.3101@gmail.com',
        address: 'Berdavan',
        phoneNumber: 37494790043,
        salary: 10,
        officialSalary: 24,
        joinedOn: '2020-06-01',
        updatedSalaryOnDate: '2020-10-01',
        nextReviewDate: '2021-01-01',
        onProbation: false,
        active: true,
      },
      {
        id: 2,
        firstName: 'Arayik',
        lastName: 'Hovhannisyan',
        title: 'Junior Web Developer',
        nationality: 'Armenian',
        country: 'Armenia',
        city: 'Yerevan',
        email: 'ara.hovhannisyan99@gmail.com',
        address: 'Yerevan',
        phoneNumber: 37499999999,
        salary: 10,
        officialSalary: 24,
        joinedOn: '2020-09-01',
        updatedSalaryOnDate: '2020-10-01',
        nextReviewDate: '2020-12-01',
        onProbation: true,
        active: false,
      },
    ],
  },
  {
    method: 'put',
    url: /.*\/employee\/[0-9]*.*/,
    statusCode: 200,
    body: [
      {
        id: 1,
        firstName: 'Hayk',
        lastName: 'Karyan',
        title: 'Junior Web Developer',
        nationality: 'Armenian',
        country: 'Armenia',
        city: 'Noyemberyan',
        email: 'karyan.hk.3101@gmail.com',
        address: 'Berdavan',
        phoneNumber: '+374 94 790043',
        salary: 10,
        officialSalary: 24,
        joinedOn: '2020-06-01',
        updatedSalaryOnDate: '2020-10-01',
        nextReviewDate: '2021-01-01',
        onProbation: false,
        active: true,
      },
    ],
  },
  {
    method: 'delete',
    url: /.*\/employee\/[0-9]*.*/,
    statusCode: 200,
    body: [
      {
        id: 1,
        firstName: 'Hayk',
        lastName: 'Karyan',
        title: 'Junior Web Developer',
        nationality: 'Armenian',
        country: 'Armenia',
        city: 'Noyemberyan',
        email: 'karyan.hk.3101@gmail.com',
        address: 'Berdavan',
        phoneNumber: '+374 94 790043',
        salary: 10,
        officialSalary: 24,
        joinedOn: '2020-06-01',
        updatedSalaryOnDate: '2020-10-01',
        nextReviewDate: '2021-01-01',
        onProbation: false,
        active: true,
      },
      {
        id: 2,
        firstName: 'Arayik',
        lastName: 'Hovhannisyan',
        title: 'Junior Web Developer',
        nationality: 'Armenian',
        country: 'Armenia',
        city: 'Yerevan',
        email: 'ara.hovhannisyan99@gmail.com',
        address: 'Yerevan',
        phoneNumber: '+374 99 999999',
        salary: 10,
        officialSalary: 24,
        joinedOn: '2020-09-01',
        updatedSalaryOnDate: '2020-10-01',
        nextReviewDate: '2020-12-01',
        onProbation: true,
        active: false,
      },
    ],
  },
];
