import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Form',
})
export default class Form extends Vue {
  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: false })
  lazyValidation!: boolean;

  @Prop({ default: false })
  readonly!: boolean;

  @Prop({ default: false })
  value!: boolean;
}
