import { Component, Prop, Vue } from 'vue-property-decorator';
import { CommonMixin } from '@/mixins';

@Component({
  name: 'TextField',
  mixins: [CommonMixin],
})
export default class TextField extends Vue {
  @Prop({ default: '' })
  appendIcon!: string;

  @Prop({ default: '' })
  appendOuterIcon!: string;

  @Prop({ default: false })
  autofocus!: boolean;

  @Prop({ default: '' })
  backgroundColor!: string;

  @Prop({ default: '$clear' })
  clearIcon!: string;

  @Prop({ default: false })
  clearable!: boolean;

  @Prop({ default: '' })
  color!: string;

  @Prop({ default: false })
  counter!: boolean | number | string;

  @Prop({ default: null })
  counterValue!: Function;

  @Prop({ default: false })
  dark!: boolean;

  @Prop({ default: false })
  dense!: boolean;

  @Prop({ default: false })
  error!: boolean;

  @Prop({ default: 1 })
  errorCount!: number | string;

  @Prop({ default: '' })
  errorMessages!: string;

  @Prop({ default: false })
  filled!: boolean;

  @Prop({ default: false })
  flat!: boolean;

  @Prop({ default: false })
  fullWidth!: boolean;

  @Prop({ default: '' })
  height!: number | string;

  @Prop({ default: 'auto' })
  hideDetails!: number | string;

  @Prop({ default: '' })
  hint!: string;

  @Prop({ default: '' })
  id!: string;

  @Prop({ default: '' })
  label!: string;

  @Prop({ default: false })
  light!: boolean;

  @Prop({ default: 2 })
  loaderHeight!: number | string;

  @Prop({ default: false })
  loading!: boolean | string;

  @Prop({ default: '' })
  messages!: string;

  @Prop({ default: false })
  outlined!: boolean;

  @Prop({ default: false })
  persistentHint!: boolean;

  @Prop({ default: '' })
  placeholder!: string;

  @Prop({ default: '' })
  prefix!: string;

  @Prop({ default: '' })
  prependIcon!: string;

  @Prop({ default: '' })
  prependInnerIcon!: string;

  @Prop({ default: false })
  readonly!: boolean;

  @Prop({ default: false })
  reverse!: boolean;

  @Prop({ default: false })
  rounded!: boolean;

  @Prop({ default: () => [] })
  rules!: Array<any>;

  @Prop({ default: false })
  shaped!: boolean;

  @Prop({ default: false })
  singleLine!: boolean;

  @Prop({ default: false })
  solo!: boolean;

  @Prop({ default: false })
  soloInverted!: boolean;

  @Prop({ default: false })
  success!: boolean;

  @Prop({ default: '' })
  successMessages!: string;

  @Prop({ default: '' })
  suffix!: string;

  @Prop({ default: 'text' })
  type!: string;

  @Prop({ default: false })
  validateOnBlur!: boolean;

  @Prop({ default: '' })
  value!: any;

  @Prop({ default: false })
  showArrows!: boolean;
}
