import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Link',
})
export default class Link extends Vue {
  @Prop({ default: '' })
  url!: string;

  @Prop({ default: true })
  openInNewTab!: boolean;

  get openInNewTabComputed() {
    return this.openInNewTab ? '_blank' : '';
  }
}
