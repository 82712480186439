import { Component, Prop, Vue } from 'vue-property-decorator';
import { CommonMixin } from '@/mixins';

@Component({
  name: 'Checkbox',
  mixins: [CommonMixin],
})
export default class Checkbox extends Vue {
  @Prop({ default: '' })
  color!: string;

  @Prop({ default: false })
  dark!: boolean;

  @Prop({ default: false })
  indeterminate!: boolean;

  @Prop({ default: '$checkboxIndeterminate' })
  indeterminateIcon!: string;

  @Prop({ default: false })
  light!: boolean;

  @Prop({ default: '$checkboxOff' })
  offIcon!: string;

  @Prop({ default: '$checkboxOn' })
  onIcon!: string;

  @Prop({ default: false })
  ripple!: boolean;

  @Prop({ default: false })
  value!: boolean;
}
