import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { MessageType } from '@/enums';
import ReviewComponents from '@components/employee/review';
import Components from '@components/employee';

const { EmployeeModal } = Components;
const { ReviewTable, ReviewModal } = ReviewComponents;

@Component({
  name: 'EmployeeView',
  components: {
    EmployeeModal,
    ReviewTable,
    ReviewModal,
  },
})
export default class EmployeeView extends Vue {
  employeeDialog = false;
  reviewDialog = false;
  employeeId: number = +this.$route.params.id;
  currentItem: object = {};

  toggleEmployeeDialog() {
    this.employeeDialog = true;
    this.currentItem = { ...this.currentEmployee };
  }

  toggleReviewDialog() {
    this.reviewDialog = !this.reviewDialog;
  }

  @Getter('getCurrentEmployee')
  currentEmployee!: any;

  @Action('ShowMessage')
  showMessage!: (payload: any) => void;

  @Action('GetEmployee')
  getEmployee!: (id: any) => Promise<void>;

  async created() {
    try {
      await this.getEmployee(this.employeeId);
    } catch (error) {
      this.showMessage({
        content: error,
        type: MessageType.Error,
      });
    }
  }
}
