import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import { MessageType } from '@/enums';
import { Employee } from '@/models';
import { helper } from '@/helper';
import DateField from '@shared/DateField/index.vue';

@Component({
  name: 'EmployeeModal',
  components: {
    DateField,
  },
})
export default class EmployeeModal extends Vue {
  emailField: any[] = [];
  phoneField: any[] = [];
  addressField: any[] = [];
  countryField: any[] = [];
  maxTwentyFields: any[] = [];
  nationalityField: any[] = [];
  requiredField: any[] = [];
  salaryField: any[] = [];
  isValid = false;

  @Prop({ default: 0 })
  employeeId!: number;

  @Prop({ default: false })
  dialogIsActive!: boolean;

  @Prop({ default: 'Add Employee' })
  dialogTitle!: string;

  @Prop({ default: false })
  isEdit!: boolean;

  @Getter('getCurrentEmployee')
  employee!: Employee;

  @Action('GetEmployee')
  getEmployee!: (id: number) => Promise<Employee>;

  @Action('CreateEmployee')
  createEmployee!: (employee: Employee) => Promise<void>;

  @Action('EditEmployee')
  editEmployee!: (employee: Employee) => Promise<void>;

  @Action('ShowMessage')
  showMessage!: (payload: any) => void;

  @Mutation('setCurrentEmployee')
  setCurrentEmployee!: (payload: any) => void;

  get computedSalary() {
    return this.employee.salary;
  }

  set computedSalary(val) {
    this.employee.salary = Number(val);
  }

  get computedOfficialSalary() {
    return this.employee.officialSalary;
  }

  set computedOfficialSalary(val) {
    this.employee.officialSalary = Number(val);
  }

  async created() {
    if (!this.isEdit) this.setCurrentEmployee({});
    if (this.isEdit && this.employeeId > 0) {
      try {
        await this.getEmployee(this.employeeId);
      } catch (error) {
        this.showMessage({
          content: error,
          type: MessageType.Error,
        });
      }
    }
  }

  getValidationRules() {
    this.salaryField = [helper.salaryRule];
    this.phoneField = [helper.phone];
    this.emailField = [helper.email];
    this.countryField = [helper.required, helper.maxChars(2)];
    this.addressField = [helper.required, helper.maxChars(50)];
    this.nationalityField = [helper.required, helper.maxChars(3)];
    this.maxTwentyFields = [helper.required, helper.maxChars(20)];
  }

  async createOrEdit() {
    try {
      if (!this.isValid) {
        this.showMessage({
          content: 'Missing Or Invalid Fields',
          type: MessageType.Error,
        });
        return;
      }
      if (this.isEdit) {
        await this.editEmployee(this.employee);
        this.showMessage({
          content: 'Employee Edited',
        });
      } else {
        await this.createEmployee(this.employee);
        this.showMessage({
          content: 'Employee Added',
        });
      }
      this.$emit('close');
    } catch (error) {
      this.showMessage({
        content: error,
        type: MessageType.Error,
      });
    }
  }

  checkValidation(action: any) {
    this.getValidationRules();
    setTimeout(() => {
      if (
        (this.$refs.employeeForm as Vue & {
          validate: () => boolean;
        }).validate()
      ) {
        this.isValid = true;
      }
      action();
    });
  }
}
