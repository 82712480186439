import authMock from './auth.mock';
import employeeMock from './employee.mock';
import employeeReviewMock from './employeeReview.mock';
import userMock from './user.mock';

export default [
  ...authMock,
  ...employeeReviewMock,
  ...employeeMock,
  ...userMock,
];
