export const helper = {
  required: (value: any) => !!value || 'Field is required',
  minChars(length: number) {
    return (value: any) => value.length >= length || `Min ${length} characters`;
  },
  maxChars(length: number) {
    return (value: any) => value.length <= length || `Max ${length} characters`;
  },
  email: (value: any) => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || 'Invalid e-mail.';
  },
  phone: (value: any) => {
    const pattern = /^(([374]{3}|[0]{1}))?([1-9]{2})(\d{6})$/g;
    return (
      pattern.test(value) ||
      'Invalid phone number (Must be +37499101010 or 099101010)'
    );
  },
  salaryRule: (value: any) => {
    return (
      (!isNaN(parseFloat(value)) && value >= 0) ||
      'Field should not be negative'
    );
  },
};
