import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'Avatar',
})
export default class Avatar extends Vue {
  @Prop({ default: '' })
  color!: string;

  @Prop({ default: '' })
  height!: number | string;

  @Prop({ default: false })
  left!: boolean;

  @Prop({ default: '' })
  maxHeight!: number | string;

  @Prop({ default: '' })
  maxWidth!: number | string;

  @Prop({ default: '' })
  minHeight!: number | string;

  @Prop({ default: '' })
  minWidth!: number | string;

  @Prop({ default: false })
  right!: boolean;

  @Prop({ default: 'rounded-circle' })
  rounded!: boolean | string;

  @Prop({ default: 48 })
  size!: number | string;

  @Prop({ default: false })
  tile!: boolean;

  @Prop({ default: '' })
  width!: number | string;
}
