import { Component, Prop, Vue } from 'vue-property-decorator';
import { CommonMixin } from '@/mixins';

@Component({
  name: 'Card',
  mixins: [CommonMixin],
})
export default class Card extends Vue {
  @Prop({ default: '' })
  activeClass!: string;

  @Prop({ default: false })
  append!: boolean;

  @Prop({ default: '' })
  color!: string;

  @Prop({ default: false })
  dark!: boolean;

  @Prop({ default: '' })
  elevation!: number | string;

  @Prop({ default: false })
  exact!: boolean;

  @Prop({ default: '' })
  exactActiveClass!: string;

  @Prop({ default: false })
  flat!: boolean;

  @Prop({ default: false })
  light!: boolean;

  @Prop({ default: '' })
  width!: string | number;

  @Prop({ default: '' })
  minWidth!: string | number;

  @Prop({ default: '' })
  maxWidth!: string | number;

  @Prop({ default: '' })
  minHeight!: number | string;

  @Prop({ default: '' })
  height!: number | string;

  @Prop({ default: '' })
  maxHeight!: number | string;

  @Prop({ default: false })
  hover!: boolean;

  @Prop({ default: '' })
  href!: string | object;

  @Prop({ default: '' })
  img!: string;

  @Prop({ default: false })
  link!: boolean;

  @Prop({ default: 4 })
  loaderHeight!: number | string;

  @Prop({ default: false })
  loading!: boolean | string;

  @Prop({ default: false })
  nuxt!: boolean;

  @Prop({ default: true })
  outlined!: boolean;

  @Prop({ default: false })
  raised!: boolean;

  @Prop({ default: false })
  replace!: boolean;

  @Prop({ default: '' })
  ripple!: boolean | object;

  @Prop({ default: '' })
  rounded!: boolean | string;

  @Prop({ default: false })
  shaped!: boolean;

  @Prop({ default: 'div' })
  tag!: string;

  @Prop({ default: '' })
  target!: string;

  @Prop({ default: false })
  tile!: boolean;

  @Prop({ default: '' })
  to!: string | object;
}
