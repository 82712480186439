import api from '@utils/api';
import {
  getCurrencySign,
  createCurrencyRequestConfig,
} from '@/helper/currency';
import { MessageType } from '@/enums';

const state = { currency: '', currencyRate: 1 };

const getters = {
  getCurrency: (state: any) => state.currency,
  getCurrencyRate: (state: any) => state.currencyRate,
};

const actions = {
  async GetCurrency({ commit, dispatch }: any, paylaod: any) {
    dispatch('setLoading', true);
    const { currency } = paylaod;
    const toCurrency = currency.to.toLowerCase();
    const currencySign = getCurrencySign(currency.to);
    const queryParams = { format: 'json', ...currency };
    const config = createCurrencyRequestConfig('currency/convert', queryParams);
    try {
      const data = await api.get({
        config,
      });
      const { rates } = data;
      const currencyRate = rates[toCurrency].rate;
      commit('setCurrency', currencySign);
      commit('setCurrencyRate', currencyRate);
    } catch (e) {
      dispatch('ShowMessage', {
        content: e,
        type: MessageType.Error,
      });
    } finally {
      dispatch('setLoading', false);
    }
  },
};

const mutations = {
  setCurrency(state: any, currency: any) {
    state.currency = currency;
  },
  setCurrencyRate(state: any, rate: any) {
    state.currencyRate = rate;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
