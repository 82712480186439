import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Col',
})
export default class Col extends Vue {
  @Prop({ default: null })
  alignSelf!: string;

  @Prop({ default: false })
  cols!: boolean | string | number;

  @Prop({ default: false })
  lg!: boolean | string | number;

  @Prop({ default: false })
  md!: boolean | string | number;

  @Prop({ default: '' })
  offset!: string | number;

  @Prop({ default: '' })
  offsetLg!: string | number;

  @Prop({ default: '' })
  offsetMd!: string | number;

  @Prop({ default: '' })
  offsetSm!: string | number;

  @Prop({ default: '' })
  offsetXl!: string | number;

  @Prop({ default: '' })
  order!: string | number;

  @Prop({ default: '' })
  orderLg!: string | number;

  @Prop({ default: '' })
  orderMd!: string | number;

  @Prop({ default: '' })
  orderSm!: string | number;

  @Prop({ default: '' })
  orderXl!: string | number;

  @Prop({ default: false })
  sm!: boolean | string | number;

  @Prop({ default: 'div' })
  tag!: string;

  @Prop({ default: false })
  xl!: boolean | string | number;
}
