import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'Icon',
})
export default class Icon extends Vue {
  @Prop({ default: '' })
  color!: string;

  @Prop({ default: false })
  dark!: boolean;

  @Prop({ default: false })
  dense!: boolean;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: false })
  large!: boolean;

  @Prop({ default: false })
  left!: boolean;

  @Prop({ default: '' })
  light!: boolean;

  @Prop({ default: false })
  right!: boolean;

  @Prop({ default: '' })
  size!: number | string;

  @Prop({ default: false })
  small!: boolean;

  @Prop({ default: 'i' })
  tag!: string;

  @Prop({ default: false })
  xLarge!: boolean;

  @Prop({ default: false })
  xSmall!: boolean;
}
