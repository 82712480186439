import Vue from 'vue';
import App from '@components/app/index.vue';
import router from '@/router';
import vuetify from '@plugins/vuetify';
import global from '@plugins/global';
import store from '@store/index.ts';
import '@utils/filters';
import '@components/app/index.scss';

Vue.use(global);
Vue.config.productionTip = false;

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app');
