import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Sidebar from '@components/layout/sidebar/index.vue';
import Content from '@components/layout/content/index.vue';
import Header from '@components/layout/header/index.vue';
import Footer from '@components/layout/footer/index.vue';

@Component({
  name: 'Layout',
  components: { Sidebar, Header, Content, Footer },
})
export default class Layout extends Vue {
  @Getter('loading')
  isLoading!: boolean;
}
