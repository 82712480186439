import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'DateField',
})
export default class DateField extends Vue {
  dateMenu = false;
  dateValue = '';

  @Prop({ default: 'Date' })
  inputLabel!: string;

  @Prop({ default: '' })
  value!: Date | string;

  @Prop({ default: '2020-01-01' })
  minDate!: Date | string;

  @Prop({ default: '2030-01-01' })
  maxDate!: Date | string;

  input(event: any) {
    this.dateMenu = false;
    this.$emit('input', event);
  }
}
