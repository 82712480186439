import { Component, Prop, Vue } from 'vue-property-decorator';
import { CommonMixin, SizeMixin } from '@/mixins';

@Component({
  name: 'Btn',
  mixins: [CommonMixin, SizeMixin],
})
export default class Btn extends Vue {
  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: 1 })
  shadow!: number;

  @Prop({ default: '' })
  color!: string;

  @Prop({ default: false })
  fullWidth!: boolean;

  @Prop({ default: false })
  rounded!: boolean;

  @Prop({ default: false })
  isRound!: boolean;

  @Prop({ default: false })
  isIcon!: boolean;

  @Prop({ default: null })
  click!: Function;
}
