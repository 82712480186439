import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/components/layout/index.vue';
import Home from '@pages/home/index.vue';
import About from '@pages/about/index.vue';
import EmployeeView from '@pages/employeeView/index.vue';
import LogIn from '@pages/logIn/index.vue';
import { AuthenticateRoute } from './auth';

Vue.use(VueRouter);

const routes = [
  {
    path: '/home',
    name: 'Home',
    redirect: '/',
    meta: { requiresAuth: true },
  },
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        components: { Home },
      },
      {
        path: '/employee/:id',
        components: { EmployeeView },
      },
      {
        path: '/about',
        components: { About },
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: LogIn,
    meta: { guest: true },
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

AuthenticateRoute(router);

export default router;
