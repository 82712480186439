import _ from 'lodash';
import mocks from '@utils/api/mock';
import { ApiRequestInternal } from '@utils/api/types';

/**
 * Web api mock.
 */
const mockApi = ({ method, url }: ApiRequestInternal): Promise<any> => {
  return new Promise(resolve => {
    url = '/' + url;
    setTimeout(() => {
      const mock = mocks.find(q => q.method === method && q.url.test(url));
      resolve(mock?.body);
    }, _.random(0, 1000));
  });
};

export default mockApi;
