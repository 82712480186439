import { Component, Vue, Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { Employee } from '@/models';
import ConfirmationModal from '@components/confirmationModal/index.vue';
import EmployeeModal from '@components/employee/modal/index.vue';
import { Action, Getter, Mutation } from 'vuex-class';
import { CurrencyType, MessageType } from '@/enums';

@Component({
  name: 'EmployeeTable',
  components: { EmployeeModal, ConfirmationModal },
})
export default class EmployeeTable extends Vue {
  dialogIsActive = false;
  confirmDialogIsActive = false;
  selectedItemId = 0;
  salaryTotal = 0;
  officialSalaryTotal = 0;
  salaryDifferenceTotal = 0;
  rate = 1;
  isVisible = null;

  headers: DataTableHeader[] = [
    { text: 'First Name', value: 'firstName' },
    { text: 'Last Name', value: 'lastName' },
    { text: 'Title', value: 'title' },
    { text: 'Salary', value: 'salary' },
    { text: 'Official Salary', value: 'officialSalary' },
    { text: 'Salary Difference', value: 'salaryDifference' },
    { text: 'Next Review', value: 'nextReviewDate' },
  ];

  @Getter('getEmployees')
  employees!: Array<Employee>;

  @Getter('getCurrencyRate')
  currencyRate!: any;

  @Getter('getCurrency')
  currencySign!: any;

  @Action('GetAllEmployees')
  getAllEmployees!: () => Promise<void>;

  @Action('DeleteEmployee')
  deleteEmployee!: (id: number) => Promise<any>;

  @Action('ShowMessage')
  showMessage!: (payload: any) => void;

  @Mutation('setCurrency')
  setCurrency!: (payload: any) => void;

  @Mutation('setCurrencyRate')
  setCurrencyRate!: (payload: any) => void;

  @Watch('currencyRate')
  handleChange() {
    this.rate = this.currencyRate;
  }
  showDialog(e: any, id: number) {
    e.stopPropagation();
    this.dialogIsActive = true;
    this.selectedItemId = id;
  }

  showConfirmationDialog(e: any, id: number) {
    e.stopPropagation();
    this.confirmDialogIsActive = true;
    this.selectedItemId = id;
  }

  async confirm(isConfirmed: boolean) {
    if (isConfirmed) {
      await this.deleteEmployee(this.selectedItemId);
    }
    this.confirmDialogIsActive = false;
  }

  openEmployeeScreen(employeeId: number) {
    this.$router.push(`/employee/${employeeId}`);
  }

  calculatedSalaries(key: any) {
    const total = this.employees.reduce(
      (a: any, b: any) => a + (b[key] * this.rate || 0),
      0
    );
    return this.parseSalary(total);
  }

  parseSalary(num: any) {
    return Number.parseFloat(num).toFixed(2);
  }

  async created() {
    try {
      await this.getAllEmployees();
      this.setCurrency(
        this.currencySign ? this.currencySign : CurrencyType.Dram
      );
      this.rate = this.currencyRate;
    } catch (error) {
      this.showMessage({
        content: error,
        type: MessageType.Error,
      });
    }
  }
}
