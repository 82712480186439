const dateObj = new Date();

const currentDate = `${dateObj.getFullYear()}-${
  dateObj.getMonth() + 1
}-${dateObj.getDate()}`;

const nextReviewDefault = `${dateObj.getFullYear()}-${
  dateObj.getMonth() + 4
}-${dateObj.getDate()}`;

export interface Employee {
  id?: number;
  firstName: string;
  lastName: string;
  title: string;
  nationality: string;
  country: string;
  city: string;
  email: string;
  address: string;
  phoneNumber: number | null;
  salary: number | null;
  officialSalary: number | null;
  joinedOn: Date | string;
  updatedSalaryOn: Date | string;
  nextReviewDate: Date | string;
  onProbation: boolean;
  active: boolean;
}

export const EmployeeDefault: Employee = {
  firstName: '',
  lastName: '',
  title: '',
  nationality: '',
  country: '',
  city: '',
  email: '',
  address: '',
  phoneNumber: null,
  salary: null,
  officialSalary: null,
  joinedOn: currentDate,
  updatedSalaryOn: currentDate,
  nextReviewDate: nextReviewDefault,
  onProbation: false,
  active: false,
};

export interface EmployeeReview {
  id?: number | null;
  employeeId?: number | null;
  reviewDate: Date | string;
  title: string;
  notes: string;
  afterSalary: number | null;
  beforeSalary: number | null;
}

export const ReviewDefault: EmployeeReview = {
  reviewDate: currentDate,
  title: '',
  notes: '',
  afterSalary: null,
  beforeSalary: null,
};
