export default [
  {
    method: 'post',
    url: /.*\/auth\/login.*/,
    statusCode: 200,
    body: {
      accessToken: 'Bearer <token>',
    },
  },
];
