import { findAndRemove, findAndReplace } from '@/utils/mostUsedFunctions';
import { EmployeeReview } from '@/models';
import api from '@utils/api';

const state = { employeeReviews: [] };

const getters = {
  getEmployeeReviews: (state: any) => state.employeeReviews,
};

const actions = {
  async GetCurrentReviews({ commit, dispatch }: any, employeeId: any) {
    try {
      dispatch('setLoading', true);
      const response: any = await api.get({
        url: `employee/${employeeId}/review`,
      });
      if (response) {
        commit('setEmployeeReviews', response);
      } else {
        commit('setEmployeeReviews', []);
      }
    } finally {
      dispatch('setLoading', false);
    }
  },
  async CreateReview({ commit }: any, reviewModel: EmployeeReview) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { employeeId, id, ...rest } = reviewModel;

    const response: any = await api.post({
      url: `employee/${employeeId}/review`,
      body: rest,
    });
    if (response) {
      commit('addReview', response.data);
    }
  },
  async EditReview({ commit }: any, reviewModel: EmployeeReview) {
    const { id, employeeId, ...rest } = reviewModel;

    const response: any = await api.put({
      url: `employee/${employeeId}/review/${id}`,
      body: rest,
    });
    if (response) {
      commit('editReview', response.data);
    }
  },
  async DeleteReview({ commit }: any, { employeeId, id }: any) {
    const response: any = await api.delete({
      url: `employee/${employeeId}/review/${id}`,
    });
    if (response.status === 'success') {
      commit('deleteReview', id);
    }
  },
};

const mutations = {
  setEmployeeReviews(state: any, employeeReviews: Array<EmployeeReview>) {
    state.employeeReviews = employeeReviews;
  },
  addReview(state: any, newReview: EmployeeReview) {
    state.employeeReviews.push(newReview);
  },
  editReview(state: any, review: EmployeeReview) {
    const { id } = review;
    findAndReplace(state.employeeReviews, id!, review);
  },
  deleteReview(state: any, id: number) {
    findAndRemove(state.employeeReviews, id);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
