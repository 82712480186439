import { CurrencyExchangeType } from '@/enums';

export const availableCurrencies = {
  toDollar: {
    from: CurrencyExchangeType.Dram,
    to: CurrencyExchangeType.Dollar,
  },
  toRuble: {
    from: CurrencyExchangeType.Dram,
    to: CurrencyExchangeType.Ruble,
  },
  toEuro: {
    from: CurrencyExchangeType.Dram,
    to: CurrencyExchangeType.Euro,
  },
  toPound: {
    from: CurrencyExchangeType.Dram,
    to: CurrencyExchangeType.Pound,
  },
};

export const currencyDropdown = [
  { value: '$ Dollar' },
  { value: '֏ Dram' },
  { value: '₽ Ruble' },
  { value: '£ Pound' },
  { value: '€ Euro' },
];

export const getCurrencySign = (currency: string) => {
  switch (currency) {
    case 'AMD':
      return '֏';
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    case 'CRC':
      return '₡';
    case 'GBP':
      return '£';
    case 'ILS':
      return '₪';
    case 'INR':
      return '₹';
    case 'JPY':
      return '¥';
    case 'KRW':
      return '₩';
    case 'NGN':
      return '₦';
    case 'PHP':
      return '₱';
    case 'PLN':
      return 'zł';
    case 'PYG':
      return '₲';
    case 'THB':
      return '฿';
    case 'UAH':
      return '₴';
    case 'VND':
      return '₫';
    case 'RUB':
      return '₽';
    default:
      return '֏';
  }
};

const currencyConfig = {
  baseURL: process.env.VUE_APP_CURRENCY_API_URL,
  headers: {
    'x-rapidapi-key': process.env.VUE_APP_CURRENCY_API_KEY,
    'x-rapidapi-host': 'currency-converter5.p.rapidapi.com',
  },
};

export const createCurrencyRequestConfig = (
  endpoint: any,
  queryParams: any
) => {
  const config = {
    url: `${currencyConfig.baseURL}/${endpoint}`,
    method: 'get',
    headers: currencyConfig.headers,
    params: queryParams,
  };
  return config;
};
