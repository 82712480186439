import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Container',
})
export default class Container extends Vue {
  @Prop({ default: false })
  fluid!: boolean;

  @Prop({ default: '' })
  id!: string;

  @Prop({ default: 'div' })
  tag!: string;
}
