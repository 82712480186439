import Table from '@shared/Table/index.vue';
import Link from '@shared/Link/index.vue';
import Img from '@shared/Img/index.vue';
import Btn from '@shared/Button/index.vue';
import Dialog from '@shared/Dialog/index.vue';
import Card from '@shared/Card/index.vue';
import CardSubtitle from '@shared/Card/CardSubtitle/index.vue';
import CardTitle from '@shared/Card/CardTitle/index.vue';
import CardText from '@shared/Card/CardText/index.vue';
import CardActions from '@shared/Card/CardActions/index.vue';
import Container from '@shared/Grid/Container/index.vue';
import Row from '@shared/Grid/Row/index.vue';
import Col from '@shared/Grid/Column/index.vue';
import Spacer from '@shared/Grid/Spacer/index.vue';
import Menu from '@shared/Menu/index.vue';
import TextField from '@shared/TextField/index.vue';
import DatePicker from '@shared/DatePicker/index.vue';
import Checkbox from '@shared/Checkbox/index.vue';
import Form from '@shared/Form/index.vue';
import Avatar from '@shared/Avatar/index.vue';
import Icon from '@shared/Icon/index.vue';
import Toast from '@shared/Toast/index.vue';
import Loading from '@shared/Loading/index.vue';
import Dropdown from '@shared/Dropdown/index.vue';

export default {
  Table,
  Link,
  Img,
  Btn,
  Dialog,
  Card,
  CardSubtitle,
  CardTitle,
  CardText,
  CardActions,
  Container,
  Row,
  Col,
  Spacer,
  Menu,
  TextField,
  DatePicker,
  Checkbox,
  Form,
  Avatar,
  Icon,
  Toast,
  Loading,
  Dropdown,
};
