import axios, { AxiosRequestConfig } from 'axios';
import {
  ApiRequestInternal,
  ApiResponse,
  QueryParams,
  RequestBody,
  RequestMethod,
} from '@utils/api/types';
import store from '@/store';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

axios.interceptors.request.use(function (config) {
  const { getToken } = store.getters;
  if (getToken) {
    config.headers.Authorization = 'Bearer ' + getToken;
  }
  return config;
});

axios.defaults.transformResponse = (data, headers) => {
  if (data && headers['content-type'].includes('application/json')) {
    return camelcaseKeys(JSON.parse(data), { deep: true });
  }
};

axios.defaults.transformRequest = (data, headers) => {
  if (data && headers['Content-Type'].includes('application/json')) {
    return JSON.stringify(snakecaseKeys(data, { deep: true }));
  }
};

const error = {
  error: {
    code: 'INTERNAL_ERROR',
    message:
      'Something went wrong. Please check your internet connection or contact our support.',
    status: 503,
    data: {},
  },
};

const defaults = {
  baseURL: process.env.VUE_APP_API_URL || 'http://localhost:3001/api',
  headers: {
    'Content-Type': 'application/json',
  },
  ...error,
};

const createRequestConfig = (
  method: RequestMethod,
  url?: string,
  body?: RequestBody,
  queryParams?: QueryParams
): AxiosRequestConfig => {
  const config: AxiosRequestConfig = {
    url: `${defaults.baseURL}/${url}`,
    method,
    headers: defaults.headers,
    data: body,
    params: queryParams,
  };
  return config;
};

/**
 * Web api client.
 */
const clientApi = async ({
  method,
  url,
  body,
  queryParams,
  config,
}: ApiRequestInternal): ApiResponse => {
  const externalConfig = {
    ...config,
    ...error,
  };
  try {
    const payload = config
      ? externalConfig
      : createRequestConfig(method, url, body, queryParams);
    const res = await axios(payload);
    return res.data;
  } catch (error) {
    return error != null ? error : defaults.error;
  }
};

export default clientApi;
