import Vuex from 'vuex';
import Vue from 'vue';
import createPersistedState from 'vuex-persistedstate';
import auth from './modules/auth';
import employee from './modules/employee';
import employeeReview from './modules/emloyeeReview';
import toast from './modules/toast';
import global from './modules/global';
import currency from './modules/currency';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    employeeReview,
    employee,
    toast,
    global,
    currency,
  },
  plugins: [
    createPersistedState({
      paths: ['auth.token'],
    }),
  ],
});
