import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import { Getter } from 'vuex-class';
import Layout from '@components/layout/index.vue';

@Component({
  name: 'App',
  components: { Layout },
})
export default class App extends Vue {
  @Getter('isAuthenticated')
  isAuthenticated!: boolean;

  created() {
    axios.interceptors.response.use(undefined, err => {
      if (this.isAuthenticated && err.response.status === 401) {
        // if you ever get an unauthorized, logout the user
        this.$store.dispatch('LogOut');
        this.$router.push('/login');
      }
      throw err;
    });
  }
}
