import { findAndRemove, findAndReplace } from '@/utils/mostUsedFunctions';
import { Employee } from '@/models';
import api from '@utils/api';

const state = { employees: [], currentEmployee: {} };

const getters = {
  getEmployees: (state: any) => state.employees,
  getCurrentEmployee: (state: any) => state.currentEmployee,
};

const actions = {
  async GetAllEmployees({ commit, dispatch }: any) {
    try {
      dispatch('setLoading', true);
      const response: any = await api.get({
        url: 'employee',
      });
      if (response) {
        const employees: any[] = [];
        response.map((res: any) => {
          const payload = {
            ...res,
            salaryDifference: res.salary - res.officialSalary,
          };
          employees.push(payload);
        });
        commit('setEmployees', employees);
      } else {
        commit('setEmployees', []);
      }
    } finally {
      dispatch('setLoading', false);
    }
  },
  async GetEmployee({ commit, dispatch }: any, id: any) {
    try {
      dispatch('setLoading', true);
      const response: any = await api.get({
        url: `employee/${id}`,
      });

      if (response) {
        commit('setCurrentEmployee', response);
      }
    } finally {
      dispatch('setLoading', false);
    }
  },
  async CreateEmployee({ commit }: any, employeeModel: Employee) {
    const response: any = await api.post({
      url: 'employee',
      body: employeeModel,
    });
    if (response) {
      const employee = {
        ...employeeModel,
        salaryDifference: employeeModel.salary! - employeeModel.officialSalary!,
      };
      commit('addEmployee', employee);
    }
  },
  async EditEmployee({ commit }: any, employeeModel: Employee) {
    const { id, ...rest } = employeeModel;
    const response: any = await api.put({
      url: `employee/${id}`,
      body: rest,
    });
    if (response) {
      const employee = {
        ...employeeModel,
        salaryDifference: employeeModel.salary! - employeeModel.officialSalary!,
      };
      commit('editEmployee', employee);
      commit('setCurrentEmployee', employee);
    }
  },
  async DeleteEmployee({ commit }: any, id: number) {
    const response: any = await api.delete({
      url: `employee/${id}`,
    });
    if (response.status === 'success') {
      commit('deleteEmployee', id);
    }
  },
};

const mutations = {
  setEmployees(state: any, employees: Array<Employee>) {
    state.employees = employees;
  },
  setCurrentEmployee(state: any, currentEmployee: Employee) {
    state.currentEmployee = currentEmployee;
  },
  addEmployee(state: any, newEmployee: Employee) {
    state.employees.push(newEmployee);
  },
  editEmployee(state: any, employeeModel: Employee) {
    const { id } = employeeModel;
    findAndReplace(state.employees, id!, employeeModel);
  },
  deleteEmployee(state: any, id: number) {
    findAndRemove(state.employees, id);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
