const state = {
  isLoading: false,
};

const getters = {
  loading: (state: any) => state.isLoading,
};

const actions = {
  setLoading({ commit }: any, loading: boolean) {
    commit('setLoading', loading);
  },
};

const mutations = {
  setLoading(state: any, loading: boolean) {
    state.isLoading = loading;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
