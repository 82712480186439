import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { MessageType } from '@/enums';

@Component({ name: 'Toast' })
export default class Toast extends Vue {
  @Getter('getToastText')
  toastText!: string;

  @Getter('getToastType')
  toastType!: string;

  @Getter('getToastActive')
  toastIsActive!: boolean;

  @Action('SetActive')
  setActive!: (isActive: boolean) => void;

  get computedToastActive() {
    return this.toastIsActive;
  }

  set computedToastActive(val) {
    this.setActive(val);
  }

  get color() {
    switch (this.toastType) {
      case MessageType.Error:
        return '#D32F2F';
      case MessageType.Success:
        return '#1E4355';
      default:
        return '#1E4355';
    }
  }
}
