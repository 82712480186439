import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

@Component({
  name: 'Table',
})
export default class Table extends Vue {
  @Prop({ default: () => [] })
  headers!: DataTableHeader[];

  @Prop({ default: () => [] })
  items!: Array<any>;

  @Prop({ default: 5 })
  itemsPerPage!: number;

  @Prop({ default: false })
  disableFiltering!: boolean;

  @Prop({ default: false })
  disablePagination!: boolean;

  @Prop({ default: false })
  disableSort!: boolean;

  @Prop({ default: true })
  multiSort!: boolean;

  @Prop({ default: false })
  singleSelect!: boolean;

  @Prop({ default: () => [] })
  sortBy?: Array<string>;

  @Prop({ default: false })
  dark!: boolean;
}
