export default [
  {
    method: 'get',
    url: /.*\/employee\/[0-9]*\/review.*/,
    statusCode: 200,
    body: {
      offset: 0,
      pageSize: 10,
      data: [
        {
          id: 1,
          employeeId: 1,
          salary: 10,
          reviewDate: '2021-01-01',
          title: 'Junior Web Developer',
          notes: 'notes',
          afterSalary: 200,
          beforeSalary: 100,
        },
        {
          id: 2,
          employeeId: 1,
          salary: 100,
          reviewDate: '2021-01-01',
          title: 'Junior Web Developer',
          notes: 'notes',
          afterSalary: 400,
          beforeSalary: 100,
        },
      ],
    },
  },
  {
    method: 'post',
    url: /.*\/employee\/[0-9]*\/review.*/,
    statusCode: 200,
    body: {
      offset: 0,
      pageSize: 10,
      data: [
        {
          id: 1,
          employeeId: 1,
          salary: 10,
          reviewDate: '2021-01-01',
          title: 'Junior Web Developer',
          notes: 'notes',
          afterSalary: 200,
          beforeSalary: 100,
        },
        {
          id: 2,
          employeeId: 1,
          salary: 100,
          reviewDate: '2021-01-01',
          title: 'Junior Web Developer',
          notes: 'notes',
          afterSalary: 400,
          beforeSalary: 100,
        },
      ],
    },
  },
  {
    method: 'put',
    url: /.*\/employee\/[0-9]*\/review\/[0-9]*.*/,
    statusCode: 200,
    body: {
      offset: 0,
      pageSize: 10,
      data: [
        {
          id: 1,
          employeeId: 1,
          salary: 10,
          reviewDate: '2021-01-01',
          title: 'Junior Web Developer',
          notes: 'notes',
          afterSalary: 200,
          beforeSalary: 100,
        },
      ],
    },
  },
  {
    method: 'delete',
    url: /.*\/employee\/[0-9]*\/review\/[0-9]*.*/,
    statusCode: 200,
    body: {
      offset: 0,
      pageSize: 10,
      data: [
        {
          id: 1,
          employeeId: 1,
          salary: 10,
          reviewDate: '2021-01-01',
          title: 'Junior Web Developer',
          notes: 'notes',
          afterSalary: 200,
          beforeSalary: 100,
        },
        {
          id: 2,
          employeeId: 1,
          salary: 100,
          reviewDate: '2021-01-01',
          title: 'Junior Web Developer',
          notes: 'notes',
          afterSalary: 400,
          beforeSalary: 100,
        },
      ],
    },
  },
];
