import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { MessageType } from '@/enums';

@Component({
  name: 'UserCard',
})
export default class UserCard extends Vue {
  loading = false;

  @Getter('getUser')
  user!: any;

  @Action('GetCurrentUser')
  getCurrentUser!: () => Promise<void>;

  @Action('ShowMessage')
  showMessage!: (payload: any) => void;

  @Action('LogOut')
  logOut!: () => Promise<void>;

  handleLogOut() {
    this.logOut();
    this.$router.push('/login');
  }

  async created() {
    try {
      this.loading = true;
      await this.getCurrentUser();
    } catch (error) {
      this.showMessage({
        content: error,
        type: MessageType.Error,
      });
    } finally {
      this.loading = false;
    }
  }
}
