import { Component, Vue } from 'vue-property-decorator';
import Components from '@components/employee';
const { EmployeeModal, EmployeeTable } = Components;

@Component({
  name: 'Home',
  components: {
    EmployeeModal,
    EmployeeTable,
  },
})
export default class Home extends Vue {
  dialogIsActive = false;

  toggleDialog() {
    this.dialogIsActive = !this.dialogIsActive;
  }
}
