import { PluginObject } from '@plugins/types';
import Components from '@components/shared';

const globalPlugin: PluginObject<null> = {
  install(Vue) {
    const componentNamePrefix = 'VS-';
    for (const component in Components) {
      Vue.component(
        `${componentNamePrefix}${component}`,
        (Components as any)[component]
      );
    }
  },
};

export default globalPlugin;
