import { Component, Prop, Vue } from 'vue-property-decorator';
import { CommonMixin } from '@/mixins';

@Component({
  name: 'Dialog',
  mixins: [CommonMixin],
})
export default class Dialog extends Vue {
  @Prop({ default: '' })
  activator!: any;

  @Prop({ default: false })
  attach!: any;

  @Prop({ default: 0 })
  closeDelay!: number | string;

  @Prop({ default: '' })
  contentClass!: string;

  @Prop({ default: false })
  dark!: boolean;

  @Prop({ default: false })
  eager!: boolean;

  @Prop({ default: false })
  fullScreen!: boolean;

  @Prop({ default: false })
  hideOverlay!: boolean;

  @Prop({ default: false })
  internalActivator!: boolean;

  @Prop({ default: false })
  light!: boolean;

  @Prop({ default: 'auto' })
  width!: string | number;

  @Prop({ default: 'none' })
  maxWidth!: string | number;

  @Prop({ default: false })
  noClickAnimation!: boolean;

  @Prop({ default: 0 })
  openDelay!: number | string;

  @Prop({ default: false })
  openOnFocus!: boolean;

  @Prop({ default: false })
  openOnHover!: boolean;

  @Prop({ default: 'center center' })
  origin!: string;

  @Prop({ default: false })
  persistent!: boolean;

  @Prop({ default: true })
  retainFocus!: boolean;

  @Prop({ default: '' })
  returnValue!: any;

  @Prop({ default: false })
  scrollable!: boolean;

  @Prop({ default: 'scale-transition' })
  transition!: string | boolean;

  @Prop({ default: '' })
  value!: any;
}
