import mockApi from '@utils/api/api.mock';
import webApi from '@utils/api/api.client';
import { ApiRequest } from '@utils/api/types';
import { apiMock } from '@config/var';

const api = apiMock ? mockApi : webApi;

export default {
  api,
  get: (args: ApiRequest) => api({ ...args, method: 'get' }),
  post: (args: ApiRequest) => api({ ...args, method: 'post' }),
  put: (args: ApiRequest) => api({ ...args, method: 'put' }),
  patch: (args: ApiRequest) => api({ ...args, method: 'patch' }),
  delete: (args: ApiRequest) => api({ ...args, method: 'delete' }),
};
